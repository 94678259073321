import React, { useCallback } from 'react';
import { getFullOrganizationConsoleUrl } from './common/env';
import { Loader } from '@getvim/atomic-ui';
import { useAuth0Wrapper } from './useAuth0Wrapper';
import StopLookingAtTheCodeAndGoToNewConsole from './components/common/StopLookingAtTheCodeAndGoToNewConsole';

export function Secure({ children }) {
  const { isAuthenticated, isLoading } = useAuth0Wrapper();

  const OnGoToNewConsoleClicked = useCallback(() => {
    const shouldUseUniversalLoginAndAddAlreadyLoggedIn = false;
    const newConsoleUrl = getFullOrganizationConsoleUrl(
      shouldUseUniversalLoginAndAddAlreadyLoggedIn,
    );
    window.location.replace(newConsoleUrl);
  }, []);

  if (isLoading) return <Loader className="center" />;

  if (!isAuthenticated)
    return (
      <StopLookingAtTheCodeAndGoToNewConsole
        isOpen={true}
        onGoToNewConsole={OnGoToNewConsoleClicked}
      />
    );

  return isAuthenticated && children;
}
