import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography, styled } from '@material-ui/core';
import MovingSvg from '../../assets/img/moving.svg';

interface ActionDialogProps {
  isOpen: boolean;
  onGoToNewConsole: () => void;
}

const StopLookingAtTheCodeAndGoToNewConsole = ({ isOpen, onGoToNewConsole }: ActionDialogProps) => {
  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <CenteredDialogTitle>We have moved to the new console!</CenteredDialogTitle>
      <CenteredDialogContent>
        <img src={MovingSvg} />
      </CenteredDialogContent>
      <CenteredDialogActions>
        <ActionButton onClick={onGoToNewConsole} variant="contained" color="primary" autoFocus>
          Click Here
        </ActionButton>
      </CenteredDialogActions>
      <div style={{ alignSelf: 'center' }}>
        <HelpText>For help, contact the OMT team</HelpText>
      </div>
    </Dialog>
  );
};

export default StopLookingAtTheCodeAndGoToNewConsole;

const CenteredDialogTitle = styled(DialogTitle)({
  alignSelf: 'center',
  '& .MuiTypography-h6': {
    fontSize: '24px',
  },
});

const CenteredDialogContent = styled(DialogContent)({
  alignSelf: 'center',
  marginTop: '-40px',
});

const CenteredDialogActions = styled(DialogActions)({
  alignSelf: 'center',
});

const ActionButton = styled(Button)({
  width: '320px',
  height: '50px',
  fontSize: '18px',
  fontWeight: 'bold',
  textTransform: 'none',
  backgroundColor: '#029be5',
  '&:hover': {
    backgroundColor: '#017cb3',
  },
});

const HelpText = styled(Typography)({
  fontSize: '12px',
  color: '#4F4F4F',
  marginBottom: '15px',
});
