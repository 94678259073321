import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { Main } from './components/main/Main';
import { Secure } from './Secure';
import { auth0Config } from './auth0/config';
import { ResetPasswordRedirect } from './ehr-login/ResetPasswordRedirect';
import { SSOLogin } from './ehr-login/sso-login/SsoLogin';

import 'bootstrap/less/bootstrap.less';
import '@getvim/atomic-ui/assets/styles/main.less';
import './app.less';
import { featureFlagsClient } from './services';

export const App = () => {
  return (
    <ThemeVariablesWrapper theme={themes.vim}>
      <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
        <Router>
          <Switch>
            <Route path="/ehrResetPasswordRedirect">
              <ResetPasswordRedirect />
            </Route>
            <Route path="/sso-login">
              <SSOLogin />
            </Route>
            <Route path="/">
              <Auth0Provider {...auth0Config}>
                <Secure>
                  <Main />
                </Secure>
              </Auth0Provider>
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </FeatureFlagProvider>
    </ThemeVariablesWrapper>
  );
};
